<template>
    <CompTable title="操作记录" :columns="columns" table-api="/gateway/api/symanage/pc/report/listReportLogPage">
        <template #search="evt">
            <Input v-model="evt.search.staffName" placeholder="操作人姓名"></Input>
            <Select v-model="evt.search.opType" placeholder="操作类型">
                <Option value="1">查看</Option>
                <Option value="2">审核</Option>
                <Option value="3">驳回</Option>
                <Option value="4">修改</Option>
                <Option value="5">发布</Option>
            </Select>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            api: `/gateway/api/symanage/pc/report/listReportLogPage`,
            columns: [
                {
                    title: "操作人",
                    minWidth: 150,
                    align: "center",
                    key: "staffName",
                },
                {
                    title: "账号",
                    minWidth: 200,
                    align: "center",
                    key: "account",
                },
                // {
                //     title: "操作周报",
                //     minWidth: 150,
                //     align: "center",
                //     key: "viewUser",
                // },
                {
                    title: "操作类型",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("p", ["查看", "审核通过", "驳回", "修改", "发布"][parseInt(params.row.opType) - 1] || "-")
                    },
                },
                {
                    title: "操作时间",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
